<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="user-form">
    <el-form-item label="年龄" prop="age">
      <el-input style="width: 100px" maxlength="3" v-model="form.age"></el-input>
    </el-form-item>
    <el-form-item label="性别" prop="gender">
      <el-radio-group v-model="form.gender">
        <el-radio label="male">男</el-radio>
        <el-radio label="female">女</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="是否有糖尿病" prop="diabetes">
      <el-switch v-model="form.diabetes"></el-switch>
    </el-form-item>
    <el-form-item label="是否有高血压" prop="hypertension">
      <el-switch v-model="form.hypertension"></el-switch>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('form')">下一步</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          age: '',
          hypertension: false,
          diabetes: false,
          gender: 'male'
        },
        rules: {
          age: [
            { required: true, message: '请输入您的年龄', trigger: 'blur' }
          ],
          gender: [
            { required: true, message: '请选择您的性别', trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.commit('SET_FUNDUS', {
              fundus: this.form
            })
            this.$emit('next')
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>
<style lang="less" scoped>
.user-form {
  text-align: left;
  padding-top: 30px;
  .el-form-item__content {
    text-align: left;
  }
  .el-step__title.is-wait {
    color: #777
  }
}
</style>
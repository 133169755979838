<template>
  <div class="upload-c">
    <el-upload
      class="upload-demo"
      v-loading="uploadLoading"
      ref="upload"
      action=""
      accept="image/*"
      :limit="1"
      :show-file-list="false"
      :on-change="fileChange"
      :on-remove="handleRemove"
      :auto-upload="false"
      drag>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
    </el-upload>
  </div>
</template>

<script>
import oss from '../utils/oss'
import { mapState } from 'vuex'
import _ from 'lodash'
const sleep = async (ms) => new Promise((resolve, reject) => setTimeout(() => resolve(), ms))
export default {
  name: 'Upload',
  data() {
    return {
      host: 'https://gzzoc-files.oss-cn-shenzhen.aliyuncs.com',
      uploadLoading: false,
      imageUrl: '',
      fileName: ''
    };
  },
  computed: mapState({
    fundus: state => state.fundus.fundus
  }),
  props: {
    msg: String
  },
  methods: {
    async fileChange (file) {
      this.uploadLoading = true
      try {
        this.sts = await oss.getSTS()
      } catch (err) {
        console.log(err)
        this.imageUrl = ''
      }
      this.uploadFile(file)
    },
    handleRemove () {
      this.imageUrl = ''
    },
    async uploadFile (file) {
      let reader = new FileReader();
      let rs = reader.readAsArrayBuffer(file.raw);
      console.log(file)
      let blob = null;
      reader.onload = async (e) => {
        if (typeof e.target.result === 'object') {
          blob = new Blob([e.target.result])
        } else {
          blob = e.target.result
        }
        await this.uploadblob(blob, file)
      }
    },
    async uploadblob (blob, file) {
      const id = new Date().getTime()
      try {
        const exName = file.name.split('.')[1]
        const result = await oss.putBlob(this.sts.Credentials)(blob, `input/${id}.${exName}`)
        this.imageUrl = result.url
        this.fileName = result.name
        this.getJSON()
      } catch (err) {
        console.log(err)
        this.imageUrl = ''
        this.$notify({
          title: '上传失败，请重新尝试',
          type: 'error'
        })
      }
    },
    async getJSON () {
      let name = this.fileName.split('.')[0]
      let url = `${this.host}/${name.replace('input', 'output')}.json`
      // let url = "https://gzzoc-files.oss-cn-shenzhen.aliyuncs.com/output/test.json"
       axios.get(url).then((res) => {
        let fundus = _.cloneDeep(this.fundus)
        fundus.result = res.data.result
        fundus.imageUrl = this.imageUrl
        fundus.fileName = this.fileName
        fundus.jsonUrl = url
        this.$store.commit('SET_FUNDUS', {
          fundus
        })
        this.uploadLoading = false
        this.$emit('next')
      }).catch(async (err) => {
        console.log(err)
        await sleep(1000)
        this.getJSON()
      })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #1F578E;
}
.upload-c  {
  margin-top: 50px;
}
</style>

<template>
  <div>
    <div id="target" class="result">
      <div class="result-table">
        <section>
          <div>年龄</div>
          <div>性别</div>
          <div>是否有糖尿病</div>
          <div>是否有高血压</div>
        </section>
        <section>
          <div>{{fundus.age}}</div>
          <div>{{genderMap[fundus.gender]}}</div>
          <div>{{fundus.diabetes ? '是' : '否'}}</div>
          <div>{{fundus.hypertension ? '是' : '否'}}</div>
        </section>
        <section class="image-box">
          <img :src="fundus.imageUrl" alt="">
        </section>
        <section>
          <div>疾病</div>
          <div>结果</div>
          <div>自信度</div>
          <div>备注</div>
        </section>
        <section v-if="fundus.result && fundus.result[0]">
          <div>糖尿病性视网膜病变</div>
          <div>{{predMap[fundus.result[0].dr.pred]}}</div>
          <div>{{fundus.result[0].dr.prob}}</div>
          <div>{{remarkMap[fundus.result[0].dr.pred]}}</div>
        </section>
        <section v-if="fundus.result && fundus.result[0]">
          <div>中风</div>
          <div>{{predMap[fundus.result[1].stroke.pred]}}</div>
          <div>{{fundus.result[1].stroke.prob}}</div>
          <div>{{remarkMap[fundus.result[1].stroke.pred]}}</div>
        </section>
      </div>
    </div>
    <el-button @click="html2Image">保存结果</el-button>
    <el-button @click="next">重新开始</el-button>
    <!-- <img v-if="resultUrl" :src="resultUrl" alt=""> -->
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      resultUrl: '',
      genderMap: {
        'male': '男',
        'female': '女',
      },
      predMap: {
        '0': '阴性',
        '1': '阳性'
      },
      remarkMap: {
        '0': '无',
        '1': '建议转诊'
      }
    }
  },
  computed: mapState({
    fundus: state => state.fundus.fundus
  }),
  methods: {
    next () {
      this.$emit('next')
    },
    html2Image () {
      window.scrollTo(0, 0)
      const target = document.getElementById('target')
      var canvas = document.createElement('canvas');
      canvas.width = target.offsetWidth * 2
      canvas.height = target.offsetHeight * 2
      canvas.style.width = target.offsetWidth + 'px'
      canvas.style.height = target.offsetHeight + 'px'
      var context = canvas.getContext('2d')
      context.scale(2, 2)
      setTimeout(() => {
        html2canvas(target, {
          useCORS: true,
          canvas: canvas,
          allowTaint: true,
          width: canvas.width,
          height: canvas.height,
          logging: true,
          background: '#fff'
        }).then((canvas) => {
          let dataUrl = canvas.toDataURL('image/png', 1.0)
          this.resultUrl = dataUrl
          const blob = this.dataURLtoBlob(dataUrl)
          this.openDownloadDialog(blob, `${new Date().getTime()}.png`)
        })
      }, 0)
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',');
      var _arr = arr[1].substring(0,arr[1].length-2);
      var mime = arr[0].match(/:(.*?);/)[1],
        bstr =atob(_arr),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime
      });
    },
    openDownloadDialog(url, saveName) {
        if(typeof url === 'object' && url instanceof Blob)
        {
          url = URL.createObjectURL(url); // 创建blob地址
        }
        var aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        var event;
        if(window.MouseEvent) event = new MouseEvent('click');
        else
        {
          event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        }
        aLink.dispatchEvent(event);
    }
  }
}
</script>
<style lang="less" scoped>
.result {
  padding: 60px 50px;
}
.result-table {
  border-left: 1px solid #666;
  border-bottom: 1px solid #666;
  .image-box {
    height: auto;
    align-items:center;
    img {
      width: 100%;
    }
  }
  section {
    display: flex;
    div {
      display: inline-block;
      flex: 1;
      height: 40px;
      line-height: 40px;
      border: 1px solid #666;
      border-left: 0;
      border-bottom: 0;
    }
  }
}
</style>
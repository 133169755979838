<template>
  <div class="home">
    <el-card class="home-box" shadow="always">
      <el-steps :active="active" align-center finish-status="success">
        <el-step title="填写信息" icon="el-icon-edit"></el-step>
        <el-step title="上传照片" icon="el-icon-upload"></el-step>
        <el-step title="查看结果" icon="el-icon-document"></el-step>
      </el-steps>
      <el-row :gutter="12">
        <div v-if="active === 0" shadow="always">
          <UserForm @next="next"/>
        </div>
        <div v-if="active === 1" shadow="always">
          <Upload @next="next"/>
        </div>
        <div v-if="active === 2" shadow="always">
          <Result @next="next" />
        </div>
      </el-row>
      <el-button class="home-btn" v-if="active === -1" style="margin-top: 12px;" @click="next">开始</el-button>
  </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Upload from '@/components/Upload.vue'
import UserForm from '@/components/UserForm.vue'
import Result from '@/components/Result.vue'

export default {
  name: 'Home',
  components: {
    Upload,
    UserForm,
    Result
  },
  data() {
    return {
      active: -1
    };
  },
  methods: {
    next() {
      if (this.active++ >= 2) {
        this.$store.commit('SET_FUNDUS', {
          fundus: {}
        })
        this.active = 0
      }
    }
  }
}
</script>

<style lang="less">
.home {
  padding-bottom: 20px;
  .home-box {
    position: relative;
    padding: 20px 30px;
    min-height: 520px;
    width: 800px;
    margin: 20px auto 0;
    box-sizing: border-box;
  }
  .home-btn {
    position: absolute;
    bottom: 200px;
    left: 50%;
    width: 250px;
    height: 80px;
    font-size: 30px;
    margin-left: -125px;
  }
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import fundus from './modules/fundus'

import api from '../plugins/api'

Vue.use(Vuex)
Vue.use(api(Vuex))

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    fundus,
  },
  plugins: [createLogger({})]
})
